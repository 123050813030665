import axios from 'axios';
import { axiosOptions } from '~/shared/modules/axios_helper';

export const POLL_GET_REQUEST_RETRY_FAIL = 'Poll GET request: All attempted retries unsuccessful';

const delay = (delayTimer) => new Promise((resolve) => setTimeout(resolve, delayTimer));

export const pollGetRequest = async (url, retryCount = 5, delayTimer = 2000) => {
  if (retryCount) {
    const response = await axios.get(
      url,
      axiosOptions(axios.CancelToken.source()),
    );

    if (response?.status === 200) {
      return response.data;
    }

    await delay(delayTimer);
    return pollGetRequest(url, retryCount - 1, delayTimer);
  }

  throw new Error(POLL_GET_REQUEST_RETRY_FAIL);
};

export const getCSRFToken = () => {
  const metaTag = document.querySelector('meta[name="csrf-token"]');
  return metaTag?.getAttribute('content') || '';
};
